var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "user-info-head",
          on: {
            click: function ($event) {
              return _vm.editCropper()
            },
          },
        },
        [
          _c("img", {
            staticClass: "img-circle img-lg",
            staticStyle: { width: "90px", height: "90px" },
            attrs: { src: _vm.options.img, title: "点击上传头像" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
            opened: _vm.modalOpened,
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { style: { height: "350px" }, attrs: { xs: 24, md: 12 } },
                [
                  _vm.visible
                    ? _c("vue-cropper", {
                        ref: "cropper",
                        attrs: {
                          img: _vm.options.img,
                          info: true,
                          autoCrop: _vm.options.autoCrop,
                          autoCropWidth: _vm.options.autoCropWidth,
                          autoCropHeight: _vm.options.autoCropHeight,
                          fixedBox: _vm.options.fixedBox,
                        },
                        on: { realTime: _vm.realTime },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-col",
                { style: { height: "350px" }, attrs: { xs: 24, md: 12 } },
                [
                  _c("div", { staticClass: "avatar-upload-preview" }, [
                    _c("img", {
                      style: _vm.previews.img,
                      attrs: { src: _vm.previews.url },
                    }),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { lg: 2, md: 2 } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: "#",
                        "http-request": _vm.requestUpload,
                        "show-file-list": false,
                        "before-upload": _vm.beforeUpload,
                      },
                    },
                    [
                      _c("el-button", { attrs: { size: "small" } }, [
                        _vm._v(" 选择 "),
                        _c("i", {
                          staticClass: "el-icon-upload el-icon--right",
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { lg: { span: 1, offset: 2 }, md: 2 } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-plus", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.changeScale(1)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { lg: { span: 1, offset: 1 }, md: 2 } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-minus", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.changeScale(-1)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { lg: { span: 1, offset: 1 }, md: 2 } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-refresh-left", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rotateLeft()
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { lg: { span: 1, offset: 1 }, md: 2 } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-refresh-right", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rotateRight()
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { lg: { span: 2, offset: 6 }, md: 2 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.uploadImg()
                        },
                      },
                    },
                    [_vm._v("提 交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }