<template>
  <div class="wholeBody" v-loading="loading">
    <div class="addBtn">
      <el-button
        class="marR15"
        type="primary"
        @click="submitForm(true)"
        :disabled="disabled"
        >保存</el-button
      >
      <el-button
        class="marR15"
        type="primary"
        @click="AddBill"
        :disabled="disabled"
        >保存并新增</el-button
      >
      <el-dropdown
        class="marR15"
        split-button
        type="primary"
        size="mini"
        icon="el-icon-circle-check"
        :disabled="showAudit"
        @click="auditBill('审核')"
        @command="auditBill"
      >
        审核
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="反审核">反审核</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown
        class="marR15"
        split-button
        type="success"
        size="mini"
        icon="el-icon-circle-check"
        :disabled="Enable"
        @click="getEnable(0)"
        @command="getEnable(1)"
      >
        启用
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="禁用">禁用</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button class="marR15" @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- 基本信息 -->
    <el-card class="baseInfo">
      <!-- 标题 -->
      <div
        class="bill-nav"
        :class="{
          'nav-audit-icon': auditOrder,
          'nav-stop-icon': stopOrder,
          'nav-noAudit-icon': noAuditOrder,
          'nav-delete-icon': deleteOrder,
          'nav-disable-icon': disableOrder,
        }"
      >
        <i
          class="el-icon-caret-right iconRule"
          v-show="isIconInfo"
          @click="getIconInfo"
        ></i>
        <i
          class="el-icon-caret-bottom iconRule"
          v-show="!isIconInfo"
          @click="getIconInfo"
        ></i>
        <span class="titleRule">基本信息</span>
      </div>
      <div v-show="!isIconInfo">
        <el-form
          ref="form"
          :model="fillRuleForm"
          :rules="rules"
          class="baseInfoBody"
        >
          <el-form-item prop="billNo" label="方案编号" label-width="80px">
            <!-- 方案编号 -->
            <el-input
              class="baseInput"
              :disabled="true"
              v-model="fillRuleForm.billNo"
              placeholder="方案编号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="billRuleName" label="方案名称" label-width="80px">
            <!-- 方案名称 -->
            <el-input
              class="baseInput"
              :disabled="disabled"
              v-model="fillRuleForm.billRuleName"
              placeholder="方案名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="优先级" label-width="80px" v-if="isPriority">
            <el-select
              class="discountUpSelect"
              v-model="fillRuleForm.discountPriority1"
              :disabled="disabled"
            >
              <el-option
                v-for="dict in dict.type.vip_discount_priority"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
          <span class="greater" v-if="isPriority">></span>
          <el-form-item prop="discountPriority1" v-if="isPriority">
            <el-select
              class="discountUpSelect"
              v-model="fillRuleForm.discountPriority2"
              :disabled="disabled"
            >
              <el-option
                v-for="dict in dict.type.vip_discount_priority"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            prop="datetime"
            label="有效日期"
            v-if="dateTimeForm"
            label-width="80px"
          >
            <!-- 有效日期 -->
            <el-date-picker
              v-model="datetime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="方案备注" label-width="80px">
            <!-- 方案备注 -->
            <el-input
              class="remarkinput"
              :disabled="disabled"
              v-model="fillRuleForm.billRemark"
              type="textarea"
              placeholder="备注长度介于 1 和 80 字符之间"
              maxlength="80"
              :autosize="{ minRows: 2, maxRows: 4 }"
            />
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <!-- 充值赠送规则 -->
    <el-card class="giveRule" v-if="ChargeGive">
      <!-- 充值赠送规则 -->
      <!-- 标题 -->
      <div>
        <i
          class="el-icon-caret-right iconRule"
          v-show="isIconRule"
          @click="getIconRule"
        ></i>
        <i
          class="el-icon-caret-bottom iconRule"
          v-show="!isIconRule"
          @click="getIconRule"
        ></i>
        <span class="titleRule">充值赠送规则</span>
      </div>
      <!-- 内容 -->
      <div v-show="!isIconRule">
        <div class="multiplyTitle">
          <el-checkbox
            v-model="fillRuleForm.isCanDiscount"
            :disabled="disabled"
          />
          <span class="checkboxTitle">
            倍增（启用倍增后，优先匹配更高金额的赠送规则，高金额未达标时，匹配低一级金额的赠送规则进行倍增）
          </span>
        </div>
        <!-- 新增表格 -->
        <div class="fillRuleListTable">
          <div
            v-for="(item, index) in fillRuleForm.largessItems"
            :key="index"
            class="fillRuleList"
            :class="{ focusfillRuleList: clickItem == index }"
            @click="focusRuleList(index)"
          >
            <div class="fillRuleListMoney">
              <span> 充值金额：{{ item.fillMoney }}元</span>
              <i class="el-icon-delete" @click="deleteRuleList(index)"></i>
            </div>
            <div v-show="item.largessMoney">
              赠送金额：{{ item.largessMoney }}元
            </div>
            <div
              v-show="
                item.largessGoodsItems.length &&
                item.largessGoodsItems.length > 0
              "
            >
              赠送商品:{{ item.largessGoodsItems.length }} 种
            </div>
            <div v-show="item.largessScore">
              赠送积分:{{ item.largessScore }} 分
            </div>
          </div>
          <div class="fillRuleList" @click="getRuleListAdd">
            <span class="fillRuleListAdd">+添加</span>
          </div>
        </div>
        <!-- 详情 -->
        <div>
          <!-- 充值金额 -->
          <div class="RuleListdetail">
            <span>充值金额:</span>
            <el-input
              class="fillRuleinput"
              v-model.number="fillRuleForm.largessItems[clickItem].fillMoney"
            ></el-input>
            <span>元</span>
          </div>
          <!-- 赠送金额 -->
          <div class="RuleListdetail">
            <el-checkbox v-model="giveMoney" :disabled="disabled" />
            <span class="checkboxTitle">赠送金额</span>
            <div v-show="giveMoney">
              <el-radio
                v-model="radioGiveMoney"
                label="1"
                class="giveMoneyRadio"
                :disabled="disabled"
                >自定义</el-radio
              >
              <el-input
                class="fillRuleinputMoney"
                v-model.number="
                  fillRuleForm.largessItems[clickItem].largessMoney
                "
                :disabled="isGiveMoneyInput"
              ></el-input>
              <span>元</span>
              <el-radio
                :disabled="disabled"
                v-model="radioGiveMoney"
                label="2"
                class="giveMoneyRadio"
                >充多少送多少</el-radio
              >
            </div>
          </div>
          <!-- 赠送商品 -->
          <div class="RuleListdetail">
            <el-checkbox v-model="giveGoods" :disabled="disabled" />
            <span class="checkboxTitle">赠送商品</span>
            <div v-show="giveGoods" class="appointGoods">
              <el-checkbox v-model="appointGoods" :disabled="disabled" />
              <span class="checkboxTitle">指定商品任选</span>
              <el-input
                v-show="appointGoods"
                class="fillRuleinputMoney"
                v-model.number="
                  fillRuleForm.largessItems[clickItem].largessGoodsCount
                "
                :disabled="disabled"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input>
              <span v-show="appointGoods">种</span>
              <el-button @click="getGoodsFrame" :disabled="disabled"
                >选择商品</el-button
              >
            </div>
          </div>
          <!-- 选择商品表格 -->
          <div v-if="giveGoods">
            <el-table
              tooltip-effect="dark"
              style="width: 100%"
              :data="fillRuleForm.largessItems[clickItem].largessGoodsItems"
              border
              max-height="400"
              v-loading="loadingGoods"
            >
              <el-table-column
                label="序号"
                align="center"
                prop="userId"
                width="80"
                type="index"
              />
              <el-table-column
                show-overflow-tooltip
                label="商品编码"
                align="center"
                prop="goodsNo"
                width="155"
              >
              </el-table-column>
              <el-table-column
                show-overflow-tooltip
                label="商品名称"
                align="center"
                prop="goodsName"
                width="130"
              />
              <el-table-column label="商品条码" align="center" prop="barcode" />
              <el-table-column
                show-overflow-tooltip
                label="规格"
                align="center"
                prop="goodsSpec"
                width="130"
              />

              <el-table-column
                label="单位"
                align="center"
                prop="unitId"
                width="120"
              >
                <template slot-scope="scope">
                  <el-select v-model="scope.row.unitId" :disabled="disabled">
                    <el-option
                      v-for="item in scope.row.units"
                      :key="item.unitId"
                      :label="item.unitName"
                      :value="item.unitId"
                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="商品类别"
                align="center"
                prop="categoryName"
                width="155"
              />
              <el-table-column
                label="价格"
                align="center"
                prop="salePrice"
                width="120"
              />
              <el-table-column label="数量" align="center" width="120">
                <template slot-scope="scope">
                  <el-input
                    v-model.number="scope.row.goodsQty"
                    :disabled="disabled"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="
                      deleteRowGoods(
                        scope.$index,
                        fillRuleForm.largessItems[clickItem].largessGoodsItems
                      )
                    "
                    type="text"
                    size="small"
                  >
                    移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 赠送优惠券 -->
          <div class="RuleListdetail">
            <el-checkbox v-model="giveCoupon" :disabled="disabled" />
            <span class="checkboxTitle">赠送优惠券</span>
          </div>
          <!-- 赠送积分 -->
          <div class="RuleListdetail">
            <el-checkbox v-model="giveScore" :disabled="disabled" />
            <span class="checkboxTitle">赠送积分</span>
            <div v-show="giveScore">
              <el-input
                :disabled="disabled"
                class="fillRuleinputMoney"
                v-model.number="
                  fillRuleForm.largessItems[clickItem].largessScore
                "
              ></el-input>
              <span>积分</span>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 级别折扣 -->
    <el-card class="leveDiscount" v-if="isLevelDiscount">
      <!-- 标题 -->
      <div>
        <i
          class="el-icon-caret-right iconRule"
          v-show="isIconDiscount"
          @click="getIconDiscount"
        ></i>
        <i
          class="el-icon-caret-bottom iconRule"
          v-show="!isIconDiscount"
          @click="getIconDiscount"
        ></i>
        <span class="titleRule">会员级别折扣</span>
      </div>
      <div v-show="!isIconDiscount" class="discountBody">
        <!-- 会员级别折扣 -->
        <div
          class="x-f addLevelItem"
          v-for="(item, index) in fillRuleForm.vipLevelItems"
          :key="index"
        >
          <span class="marR15">会员级别</span>
          <SelectFrame
            ref="selectFrame"
            v-model="item.vipLevelName"
            :tableData="vipLevelData"
            :disabled="disabled"
            @getPitchId="getDiyselectId($event, index)"
            :router="'/vipcard/base/level'"
            @click="OpenVipLeve = true"
            :normalizer="normalizerVipLevel"
          />
          <span class="marR15 marL15">折扣(%)</span>
          <el-input
            class="inputWidth"
            v-model.number="item.discountRate"
            :disabled="disabled"
          ></el-input>
          <el-button
            :disabled="disabled"
            @click.native.prevent="
              deleteRowVipLevel(index, fillRuleForm.vipLevelItems)
            "
            type="text"
            size="small"
          >
            删除
          </el-button>
        </div>
        <div class="marT15 addLevel" :disabled="disabled" @click="GetAddLevel">
          +级别
        </div>
      </div>
    </el-card>
    <!-- 适用范围 -->
    <el-card>
      <!-- 标题 -->
      <div class="Rangetitle">
        <i
          class="el-icon-caret-right iconRule"
          v-show="isIconRange"
          @click="getIconRange"
        ></i>
        <i
          class="el-icon-caret-bottom iconRule"
          v-show="!isIconRange"
          @click="getIconRange"
        ></i>
        <span class="titleRule">适用范围</span>
      </div>
      <!-- 内容 -->
      <div v-show="!isIconRange" class="bodyRange">
        <!-- 适用会员 -->
        <div class="rangeVip" v-if="isGiveRule">
          <span>适用会员</span>
          <el-radio-group
            v-model="fillRuleForm.vipLevelRangeType"
            :disabled="disabled"
          >
            <el-radio
              v-for="dict in dict.type.fill_vip_level_range_type"
              :key="dict.value"
              :label="dict.value"
              >{{ dict.label }}</el-radio
            >
          </el-radio-group>
          <!-- 指定会员等级 -->
          <div
            v-show="
              fillRuleForm.vipLevelRangeType == '2' ||
              fillRuleForm.vipLevelRangeType == '1'
            "
          >
            <el-button @click="getVipLeve" :disabled="disabled"
              >指定会员等级</el-button
            >
            <!-- 选择会员等级表格 -->
            <div>
              <el-table
                tooltip-effect="dark"
                style="width: 100%"
                :data="fillRuleForm.vipLevelItems"
                border
                max-height="400"
                v-loading="loadingVip"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  prop="userId"
                  width="80"
                  type="index"
                />
                <el-table-column
                  show-overflow-tooltip
                  label="会员级别编号"
                  align="center"
                  prop="vipLevelNo"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  label="会员级别名称"
                  align="center"
                  prop="vipLevelName"
                  width="180"
                />
                <el-table-column
                  prop="isUse"
                  label="排除"
                  width="120"
                  :show-overflow-tooltip="true"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.isExcl"
                      :active-value="1"
                      :inactive-value="0"
                    ></el-switch>
                  </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="
                        deleteRowVips(scope.$index, fillRuleForm.vipLevelItems)
                      "
                      type="text"
                      size="small"
                    >
                      移除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <!-- 适用门店 -->
        <div class="rangeShop">
          <span>适用门店</span>
          <el-radio-group
            v-model="fillRuleForm.shopRangeType"
            :disabled="disabled"
          >
            <el-radio
              v-for="dict in dict.type.fill_shop_range_type"
              :key="dict.value"
              :label="dict.value"
              >{{ dict.label }}</el-radio
            >
          </el-radio-group>
          <!-- 指定门店-->
          <div
            class="rangeVip"
            v-show="
              fillRuleForm.shopRangeType == '2' ||
              fillRuleForm.shopRangeType == '1'
            "
          >
            <el-button @click="getShopInfo" :disabled="disabled"
              >指定门店资料</el-button
            >
            <!-- 选择门店表格 -->
            <div>
              <el-table
                tooltip-effect="dark"
                style="width: 100%"
                :data="fillRuleForm.shopItems"
                border
                max-height="400"
                v-loading="loadingShopInfo"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  prop="userId"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="门店编码"
                  width="160"
                  align="center"
                  key="shopNo"
                  prop="shopNo"
                  :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                  label="门店名称"
                  align="center"
                  key="shopName"
                  prop="shopName"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="门店类型"
                  width="130"
                  align="center"
                  key="shopTypeName"
                  prop="shopTypeName"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  prop="isUse"
                  label="排除"
                  width="120"
                  :show-overflow-tooltip="true"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.isExcl"
                      :active-value="1"
                      :inactive-value="0"
                    ></el-switch>
                  </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="
                        deleteRowShopInfoS(scope.$index, fillRuleForm.shopItems)
                      "
                      type="text"
                      size="small"
                    >
                      移除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <!-- 适用商品 -->
        <div class="rangeVip" v-if="rangeGoods">
          <span>适用商品</span>
          <el-radio-group
            v-model="fillRuleForm.goodsRangeType"
            :disabled="disabled"
          >
            <el-radio
              v-for="dict in dict.type.vip_goods_range_type"
              :key="dict.value"
              :label="dict.value"
              >{{ dict.label }}</el-radio
            >
          </el-radio-group>

          <!-- 指定适用商品 -->
          <div
            class="rangeVip"
            v-show="
              fillRuleForm.goodsRangeType == '2' ||
              fillRuleForm.goodsRangeType == '1'
            "
          >
            <el-button @click="getGoodsFrame" :disabled="disabled"
              >指定商品</el-button
            >

            <el-button @click="getOpenBrand" :disabled="disabled"
              >指定品牌</el-button
            >

            <el-button @click="getOpenCategory" :disabled="disabled"
              >指定类别</el-button
            >
            <!-- 指定适用商品表格 -->
            <div>
              <el-table
                tooltip-effect="dark"
                style="width: 100%"
                :data="fillRuleForm.goodsItems"
                border
                max-height="400"
                v-loading="loadingBrand"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  prop="userId"
                  width="80"
                  type="index"
                />

                <el-table-column
                  label="类型"
                  align="center"
                  prop="lineType"
                  width="123"
                >
                  <template slot-scope="scope">
                    <dict-tag
                      :options="
                        dict.type.vip_applicable_commodities_appoint_type
                      "
                      :value="scope.row.lineType"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="编号"
                  align="center"
                  prop="lineNo"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  label="名称"
                  align="center"
                  prop="lineName"
                  width="180"
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  width="180"
                />
                <el-table-column
                  prop="isUse"
                  label="排除"
                  width="120"
                  :show-overflow-tooltip="true"
                  align="center"
                >
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.isExcl"
                      :active-value="1"
                      :inactive-value="0"
                    ></el-switch>
                  </template>
                </el-table-column>

                <el-table-column fixed="right" label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="
                        deleteRowgoods(scope.$index, fillRuleForm.goodsItems)
                      "
                      type="text"
                      size="small"
                    >
                      移除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <!-- 选择商品信息框 -->
    <SelectGoods :OpenGoods.sync="OpenGoods" @getGoodS="getGoodS" />
    <!-- 会员等级 -->
    <vipLeve :OpenVipLeve.sync="OpenVipLeve" @vipLeveList="vipLeveList" />
    <!-- 门店资料 -->
    <shopInfo :OpenShopInfo.sync="OpenShopInfo" @shopInfoList="shopInfoList" />
    <!-- 品牌 -->
    <SelectBrand :OpenBrand.sync="OpenBrand" @brandList="brandList" />
    <!-- 商品类别 -->
    <selectCategory
      :OpenCategory.sync="OpenCategory"
      @categoryList="categoryList"
    />
  </div>
</template>

<script>
import SelectGoods from "@/views/components/selectGoodsUp"; //商品
import vipLeve from "@/views/components/vipLeve"; //会员等级
import shopInfo from "@/views/components/shopInfo"; //门店资料
import SelectBrand from "@/views/components/selectBrand"; //门店资料
import selectCategory from "@/views/components/selectCategory"; //商品类别
import {
  updateOrderStatus,
  getOrderDetail,
  addOrder,
  updateOrder,
  updateEnable,
} from "@/api/vip/base/fillRule"; //会员规则
import { getBillNo } from "@/api/codeRule"; //会员充值规则号
import { listlevel } from "@/api/vip/base/level.js"; //会员级别
export default {
  name: "vipRuleDetail",
  dicts: [
    "fill_vip_level_range_type",
    "fill_shop_range_type",
    "vip_discount_priority",
    "vip_goods_range_type",
    "vip_applicable_commodities_appoint_type",
  ],
  components: { SelectGoods, vipLeve, shopInfo, SelectBrand, selectCategory },
  data() {
    return {
      //自定义搜索框字段转换 (会员级别)
      normalizerVipLevel: {
        Id: "vipLevelId",
        No: "vipLevelNo",
        Name: "vipLevelName",
      },
      vipLevelData: [], //会员级别
      rangeGoods: false, //适用商品
      isGiveRule: false, //适用会员级别范围
      isLevelDiscount: false, //级别折扣卡片
      isPriority: false, //优先级
      ChargeGive: false, //充值赠送整个卡片
      dateTimeForm: false, //有效日期
      Enable: true, //启用或禁用会员充值规则
      showAudit: true, //审核
      disabled: false, //禁用全部按钮
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      billType: undefined, //会员充值规则类型
      datetime: "", //有效日期
      disabled: false, //禁用状态
      clickItem: 0, //当前焦点下标
      OpenCategory: false, //选择类别开关
      OpenBrand: false, //选择品牌开关
      OpenShopInfo: false, //选择门店信息开关
      OpenVipLeve: false, //选择会员等级开关
      OpenGoods: false, //选择商品信息开关
      loadingBrand: false, //商品品牌加载
      loadingShopInfo: false, //门店资料适用范围表格加载
      loadingGoods: false, //赠送商品表格加载
      loadingVip: false, //会员适用范围表格加载
      loading: false, //加载
      isGiveMoneyInput: false, //赠送金额输入框
      radioGiveMoney: "1", //自定义赠送
      giveScore: false, //赠送积分
      giveCoupon: false, //赠送优惠券
      appointGoods: false, //指定商品
      giveGoods: false, //赠送商品
      giveMoney: false, //赠送金额
      isActive: false, //充值规则列表获取焦点失去焦点
      isIconInfo: false, //是否显示基本信息
      isIconRange: false, //是否显示适用范围
      isIconRule: false, //是否显示充值规则
      isIconDiscount: false, //是否显示会员级别折扣
      fillRuleForm: {
        billStatus: "",
        billNo: undefined,
        isCanDiscount: undefined,
        largessItems: [
          {
            fillMoney: 0, //充值金额
            largessMoney: undefined, //赠送金额
            largessGoodsItems: [], //赠送商品
            largessCouponItems: [], //赠送优惠券
            largessScore: undefined, //赠送积分
          },
        ], //新增充值金额
        vipLevelItems: [], //会员适用范围
        shopItems: [], //门店适用范围
        vipLevelRangeType: "0", //适用会员
        shopRangeType: "0", //适用门店
        goodsRangeType: "0", //适用商品
        discountPriority1: "1", //默认优先级会员价
        discountPriority2: "0", //会员折扣

        vipLevelItems: [{ discountRate: undefined, vipLevelId: undefined }], //会员折扣规则参与会员级别
      },
      //获取单据详情url
      urlDetail: undefined,
      //修改单据url
      urlUpdate: undefined,
      //新增单据url
      urlAdd: undefined,
      //更新单据状态url
      urlBillStatus: undefined,
      //启用停用
      urlEnable: undefined,
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则添加对应编码格式",
            trigger: ["blur", "change"],
          },
        ],
        billRuleName: [
          {
            required: true,
            message: "请输入方案名称",
            trigger: ["blur", "change"],
          },
        ],
        datetime: [
          {
            required: true,
            message: "请选择有效日期",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  props: {
    billNo: {
      required: true,
      type: Number,
    },
  },
  async mounted() {
    if (this.$route.query.type === "Update") {
      if (this.$route.query.billId) {
        this.loading = true;
        this.reset();
        //获取会员充值规则详情
        const res1 = await getOrderDetail(
          this.$route.query.billId,
          this.urlDetail
        );
        //会员充值规则赋值
        this.fillRuleForm = res1.data;
        if (this.fillRuleForm.begTime) {
          this.datetime = [
            this.fillRuleForm.begTime,
            this.fillRuleForm.endTime,
          ];
        }
        this.loading = false;
      }
    } else {
      this.reset();
      this.disabled = false;
      this.getBillNo();
    }
    if (this.billType == 301) {
      this.vipLevelData = (await listlevel()).rows; //会员等级
      this.GetAddLevel(); //会员级别折扣
    }
    if (this.billType == 302) {
      this.focusRuleList(this.fillRuleForm.largessItems.length - 1);
    }
  },
  watch: {
    //侦听传过来的会员充值规则类型
    billNo: {
      handler(newVal) {
        this.changeBillType(newVal);
        this.billType = newVal;
      },
      deep: true,
      immediate: true,
    },
    //侦听是否开启关闭按钮
    "fillRuleForm.billStatus": {
      handler(newVal) {
        if (this.fillRuleForm.billStatus == "2") {
          //打开审核图标
          this.auditOrder = true;
          //打开关闭按钮
          this.single = false;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
          //打开启用按钮
          this.Enable = false;
        } else if (this.fillRuleForm.billStatus == "3") {
          //打开已删除图标
          this.deleteOrder = true;
          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
          //关闭启用按钮
          this.Enable = true;
        } else if (this.fillRuleForm.billStatus == "0") {
          //禁用关闭按钮
          this.single = true;
          //未审核图标
          this.noAuditOrder = true;
          //禁用审核按钮
          this.showAudit = false;
          //关闭启用按钮
          this.Enable = true;
          //关闭审核图标
          this.auditOrder = false;
        } else if (this.fillRuleForm.billStatus == "4") {
          //打开关闭图标
          this.stopOrder = true;
          //禁用关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //禁用审核按钮
          this.showAudit = true;
          //关闭启用按钮
          this.Enable = true;
        } else if (this.fillRuleForm.billStatus == "") {
          //禁用审核按钮
          this.showAudit = true;
          //禁用关闭按钮
          this.single = true;
          //关闭启用按钮
          this.Enable = true;
          //关闭已禁用图标
          this.disableOrder = false;
          //关闭未审核图标
          this.noAuditOrder = false;
          //关闭审核图标
          this.auditOrder = false;
          //关闭已删除图标
          this.deleteOrder = false;
        }
      },
      immediate: true,
    },
    //有效日期
    datetime(newVal) {
      if (newVal) {
        this.fillRuleForm.begTime = newVal[0];
        this.fillRuleForm.endTime = newVal[1];
        this.rules.datetime[0].required = false;
      } else {
        this.fillRuleForm.begTime = undefined;
        this.fillRuleForm.endTime = undefined;
        this.rules.datetime[0].required = true;
      }
    },
    //单选框赠送金额
    radioGiveMoney(value) {
      if (this.radioGiveMoney === "2") {
        this.isGiveMoneyInput = true;
        this.$set(
          this.fillRuleForm.largessItems[this.clickItem],
          "largessMoney",
          this.fillRuleForm.largessItems[this.clickItem].fillMoney
        );
      } else {
        this.isGiveMoneyInput = false;
      }
    },
    "fillRuleForm.largessItems": {
      handler(newVal) {
        if (this.radioGiveMoney === "2") {
          this.$set(
            this.fillRuleForm.largessItems[this.clickItem],
            "largessMoney",
            this.fillRuleForm.largessItems[this.clickItem].fillMoney
          );
        }
      },
      immediate: true,
      deep: true,
    },
    //会员价优先1
    "fillRuleForm.discountPriority1": {
      handler(newVal) {
        if (this.fillRuleForm.discountPriority1 == "1") {
          this.fillRuleForm.discountPriority2 = "0";
        } else {
          this.fillRuleForm.discountPriority2 = "1";
        }
      },
      immediate: true,
    },
    //会员价优先2
    "fillRuleForm.discountPriority2": {
      handler(newVal) {
        if (this.fillRuleForm.discountPriority2 == "1") {
          this.fillRuleForm.discountPriority1 = "0";
        } else {
          this.fillRuleForm.discountPriority1 = "1";
        }
      },
      immediate: true,
    },
  },

  methods: {
    //级别折扣
    GetAddLevel() {
      this.fillRuleForm.vipLevelItems.push({
        discountRate: undefined,
        vipLevelId: undefined,
      });
    },
    //获取自定义搜索框选中的id (会员等级)
    getDiyselectId(id, index) {
      this.fillRuleForm.vipLevelItems[index].vipLevelId = id;
    },
    //启用停用
    async getEnable(value) {
      const num = value;
      const obj = {
        billIds: [this.fillRuleForm.billId],
        isStop: num,
      };
      await updateEnable(obj, this.urlEnable);
      if (num === 0) {
        this.$message({
          message: `启用成功`,
          type: "success",
        });
        //关闭已禁用图标
        this.disableOrder = false;
        //打开已审核
        this.auditOrder = true;
      } else {
        this.$message({
          message: `禁用成功`,
          type: "success",
        });
        //打开已禁用图标
        this.disableOrder = true;
        //关闭已审核
        this.auditOrder = false;
      }
    },
    //接口地址
    changeBillType(billType) {
      if (billType === 302) {
        //会员充值规则
        this.dateTimeForm = true; //有效日期
        this.ChargeGive = true; //充值赠送整个卡片
        this.isGiveRule = true; //适用级别范围
        this.urlAdd = "/api/system/vip/base/fill/rule/save";
        this.urlBillStatus = "/api/system/vip/base/fill/rule/updateStatus";
        this.urlDetail = "/api/system/vip/base/fill/rule/getDetail";
        this.urlUpdate = "/api/system/vip/base/fill/rule/update";
        this.urlEnable = "/api/system/vip/base/fill/rule/isStop";
      } else if (billType === 301) {
        //会员折扣规则
        this.isLevelDiscount = true; //级别折扣
        this.isPriority = true; //优先级
        this.rangeGoods = true; //适用商品
        this.urlAdd = "/api/system/vip/base/discount/rule/save";
        this.urlBillStatus = "/api/system/vip/base/discount/rule/updateStatus";
        this.urlDetail = "/api/system/vip/base/discount/rule/getDetail";
        this.urlUpdate = "/api/system/vip/base/discount/rule/update";
        this.urlEnable = "/api/system/vip/base/discount/rule/isStop";
      } else if (billType === 303) {
        //会员折扣规则
        this.dateTimeForm = true; //有效日期
        this.isGiveRule = true; //适用级别范围
        this.rangeGoods = true; //适用商品
        this.urlAdd = "/api/system/vip/base/score/rule/save";
        this.urlBillStatus = "/api/system/vip/base/score/rule/updateStatus";
        this.urlDetail = "/api/system/vip/base/score/rule/getDetail";
        this.urlUpdate = "/api/system/vip/base/score/rule/update";
        this.urlEnable = "/api/system/vip/base/score/rule/isStop";
      }
    },

    //审核会员充值规则方法
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.fillRuleForm.billStatus != "0") {
            this.$message.error("此会员充值规则不是未审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.fillRuleForm.billId],
            billStatus: 2,
            billType: this.billType,
          };
          //发送审核api
          const res = await updateOrderStatus(obj, this.urlBillStatus);
          this.fillRuleForm = res.data;
          // 弹出提示
          this.$message.success("审核成功");
          //禁用全部输入
          this.disabled = true;
          //打开已审核图标
          this.auditOrder = true;
        } else if (name === "反审核") {
          if (this.fillRuleForm.billStatus != "2") {
            this.$message.error("此会员充值规则不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.fillRuleForm.billId],
            billStatus: 0,
            billType: this.billType,
          };
          //发送审核api
          const res = await updateOrderStatus(obj, this.urlBillStatus);
          this.fillRuleForm = res.data;
          // 弹出提示
          this.$message.success("反审核成功");
          //恢复全部输入
          this.disabled = false;
          //关闭已审核图标
          this.auditOrder = false;
          //打开未审核图标
          this.noAuditOrder = true;
        }
      } catch {}
    },

    /** 提交按钮 */
    async submitForm(isBool) {
      if (this.billType === 302) {
        const num1 = this.fillRuleForm.largessItems.every(
          (v) => v.fillMoney > 0
        );

        const num2 = this.fillRuleForm.largessItems.every(
          (v) => v.largessMoney > 0 || v.largessMoney == undefined
        );
        const num3 = this.fillRuleForm.largessItems.every(
          (v) => v.largessScore > 0 || v.largessScore == undefined
        );
        if (!num1) {
          return this.$message.error("充值金额请输入正数");
        }
        if (!num2) {
          return this.$message.error("赠送金额请输入正数");
        }
        if (!num3) {
          return this.$message.error("赠送积分请输入正数");
        }
      }

      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.fillRuleForm.billId) {
            const res = await updateOrder(this.fillRuleForm, this.urlUpdate);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.fillRuleForm = res.data;
              this.$modal.msgSuccess("修改成功");
            } else {
              this.$modal.msgSuccess("保存并新增成功");
            }
          } else {
            const res = await addOrder(this.fillRuleForm, this.urlAdd);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.fillRuleForm = res.data;
              this.$modal.msgSuccess("新增成功");
            } else {
              this.$modal.msgSuccess("保存并修改成功");
            }
          }
        }
      });
    },
    //保存并新增单据
    async AddBill() {
      try {
        await this.$confirm("是否保存并新增单据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });
        await this.submitForm(false);
        await this.reset();
        this.getBillNo();
      } catch {}
    },

    //打开门店资料对话
    getShopInfo() {
      this.OpenShopInfo = true;
    },
    //导入选中门店资料
    shopInfoList(value) {
      this.loadingShopInfo = true;
      const arrLength = this.fillRuleForm.shopItems.length;
      if (arrLength > 0) {
        this.fillRuleForm.shopItems = this.fillRuleForm.shopItems.filter(
          (item) =>
            (!(JSON.stringify(item) === "{}") &&
              !(item.shopId === undefined)) ||
            !(item.shopId === undefined)
        );
      }
      for (let index = 0; index < arrLength; index++) {
        value = value.filter((v) => {
          return v.shopId != this.fillRuleForm.shopItems[index].shopId;
        });
      }

      this.fillRuleForm.shopItems.push(...value);
      this.loadingShopInfo = false;
    },

    //删除商品的内容
    deleteRowgoods(index, rows) {
      rows.splice(index, 1);
    },
    //删除门店表格的内容
    deleteRowShopInfoS(index, rows) {
      rows.splice(index, 1);
    },
    //打开会员等级对话
    getVipLeve() {
      this.OpenVipLeve = true;
    },
    //导入选中会员
    vipLeveList(value) {
      this.loadingVip = true;

      this.fillRuleForm.vipLevelItems = this.fillRuleForm.vipLevelItems.filter(
        (item) => {
          if (item) {
            const res = item.vipLevelId === undefined;
            return !res;
          }
        }
      );
      const arrLength = this.fillRuleForm.vipLevelItems.length;
      if (arrLength > 0) {
        for (let index = 0; index < arrLength; index++) {
          value = value.filter((v) => {
            return (
              v.vipLevelId != this.fillRuleForm.vipLevelItems[index].vipLevelId
            );
          });
        }
      }
      // this.$refs.selectFrame.getRow(value)
      this.fillRuleForm.vipLevelItems.push(...value);

      this.loadingVip = false;
    },
    //删除会员等级表格的内容
    deleteRowVips(index, rows) {
      rows.splice(index, 1);
    },

    //打开商品类别
    getOpenCategory() {
      this.OpenCategory = true;
    },

    //导入选中商品类别
    categoryList(value) {
      this.loadingBrand = true;
      if (this.fillRuleForm.goodsItems != []) {
        this.fillRuleForm.goodsItems = this.fillRuleForm.goodsItems.filter(
          (item) =>
            !(item.goodsId === undefined) ||
            !(item.brandId === undefined) ||
            !(item.categoryId === undefined)
        );
      }
      value.forEach((v) => {
        //声明属性
        this.$set(v, "lineType", 3);
        this.$set(v, "lineId", v.categoryId);
        this.$set(v, "lineName", v.categoryName);
        this.$set(v, "lineNo", v.categoryNo);
      });
      for (
        let index = 0;
        index < this.fillRuleForm.goodsItems.length;
        index++
      ) {
        value = value.filter((v) => {
          if (this.fillRuleForm.goodsItems[index].lineType === 3) {
            return (
              v.categoryId != this.fillRuleForm.goodsItems[index].categoryId
            );
          } else {
            return v;
          }
        });
      }

      this.fillRuleForm.goodsItems.push(...value);
      this.loadingBrand = false;
    },
    //打开选择品牌
    getOpenBrand() {
      this.OpenBrand = true;
    },

    //导入选中品牌
    brandList(value) {
      this.loadingBrand = true;
      if (this.fillRuleForm.goodsItems != []) {
        this.fillRuleForm.goodsItems = this.fillRuleForm.goodsItems.filter(
          (item) =>
            !(item.goodsId === undefined) ||
            !(item.brandId === undefined) ||
            !(item.categoryId === undefined)
        );
      }
      value.forEach((v) => {
        //声明属性
        this.$set(v, "lineType", 2);
        this.$set(v, "lineId", v.brandId);
        this.$set(v, "lineName", v.brandName);
        this.$set(v, "lineNo", v.brandNo);
      });

      for (
        let index = 0;
        index < this.fillRuleForm.goodsItems.length;
        index++
      ) {
        value = value.filter((v) => {
          if (this.fillRuleForm.goodsItems[index].lineType === 2) {
            return v.brandId != this.fillRuleForm.goodsItems[index].brandId;
          } else {
            return v;
          }
        });
      }

      this.fillRuleForm.goodsItems.push(...value);
      this.loadingBrand = false;
    },
    //打开选择商品
    getGoodsFrame() {
      this.OpenGoods = true;
    },
    //导入选中的商品
    getGoodS(value) {
      if (this.ChargeGive) {
        this.loadingGoods = true;
        const arrLength =
          this.fillRuleForm.largessItems[this.clickItem].largessGoodsItems
            .length;
        if (arrLength > 0) {
          this.fillRuleForm.largessItems[this.clickItem].largessGoodsItems =
            this.fillRuleForm.largessItems[
              this.clickItem
            ].largessGoodsItems.filter(
              (item) =>
                (!(JSON.stringify(item) === "{}") &&
                  !(item.goodsId === undefined)) ||
                !(item.goodsId === undefined)
            );
          for (let index = 0; index < arrLength; index++) {
            value = value.filter((v) => {
              return (
                v.goodsId !=
                this.fillRuleForm.largessItems[this.clickItem]
                  .largessGoodsItems[index].goodsId
              );
            });
          }
        }
        value.forEach((v) => {
          this.$set(v, "goodsQty", 0);
          v.goodsQty = 1;
        });
        this.fillRuleForm.largessItems[this.clickItem].largessGoodsItems.push(
          ...value
        );
        this.loadingGoods = false;
      }
      if (this.isLevelDiscount) {
        this.loadingBrand = true;
        if (this.fillRuleForm.goodsItems != []) {
          this.fillRuleForm.goodsItems = this.fillRuleForm.goodsItems.filter(
            (item) =>
              !(item.goodsId === undefined) ||
              !(item.brandId === undefined) ||
              !(item.categoryId === undefined)
          );
        }
        value.forEach((v) => {
          //声明属性
          this.$set(v, "lineType", 1);
          this.$set(v, "lineId", v.goodsId);
          this.$set(v, "lineName", v.goodsName);
          this.$set(v, "lineNo", v.goodsNo);
        });
        for (
          let index = 0;
          index < this.fillRuleForm.goodsItems.length;
          index++
        ) {
          value = value.filter((v) => {
            return v.goodsId != this.fillRuleForm.goodsItems[index].goodsId;
          });
        }
        this.fillRuleForm.goodsItems.push(...value);
        this.loadingBrand = false;
      }
    },
    //删除会员级别折扣
    deleteRowVipLevel(index, rows) {
      if (index === 0) {
        return;
      }
      rows.splice(index, 1);
    },
    //删除商品表格的内容
    deleteRowGoods(index, rows) {
      rows.splice(index, 1);
    },
    //新增充值规则
    getRuleListAdd() {
      this.fillRuleForm.largessItems.push({
        fillMoney: 0,
        largessMoney: undefined, //赠送金额
        largessGoodsItems: [], //赠送商品
        largessCouponItems: [], //赠送优惠券
        largessScore: undefined, //赠送积分
      });
      this.focusRuleList(this.fillRuleForm.largessItems.length - 1);
    },
    //删除充值规则某个对象
    deleteRuleList(index) {
      if (this.fillRuleForm.largessItems.length > 1) {
        this.fillRuleForm.largessItems.splice(index, 1);
      } else {
        this.fillRuleForm.largessItems = [
          {
            fillMoney: undefined, //充值金额
            largessMoney: undefined, //赠送金额
            largessScore: undefined, //赠送积分
          },
        ]; //新增充值金额
      }
    },
    //充值规则列表获取焦点
    focusRuleList(index) {
      this.clickItem = index;
      //赠送金额
      if (this.fillRuleForm.largessItems[index].largessMoney) {
        this.giveMoney = true;
      } else {
        this.giveMoney = false;
      }
      //指定商品
      if (this.fillRuleForm.largessItems[index].largessGoodsItems.length > 0) {
        this.giveGoods = true;
      } else {
        this.giveGoods = false;
      }
      //任选商品
      if (this.fillRuleForm.largessItems[index].largessGoodsCount) {
        this.appointGoods = true;
      } else {
        this.appointGoods = false;
      }
      //赠送积分
      if (this.fillRuleForm.largessItems[index].largessScore) {
        this.giveScore = true;
      } else {
        this.giveScore = false;
      }
      this.giveCoupon = false; //赠送优惠券
    },

    // 页面重置
    reset() {
      this.fillRuleForm = {
        billStatus: "",
        billNo: undefined,
        isCanDiscount: undefined,
        largessItems: [
          {
            fillMoney: 0, //充值金额
            largessMoney: undefined, //赠送金额
            largessGoodsItems: [], //赠送商品
            largessCouponItems: [], //赠送优惠券
            largessScore: undefined, //赠送积分
          },
        ], //新增充值金额
        vipLevelItems: [], //会员适用范围
        shopItems: [], //门店适用范围
        goodsItems: [], //商品适用范围
        vipLevelRangeType: "0", //适用会员
        shopRangeType: "0", //适用门店
        goodsRangeType: "0", //适用商品
        discountPriority1: "1", //默认优先级会员价
        discountPriority2: "0", //会员折扣
      };
      //有效日期
      this.datetime = "";
      this.resetForm("form");
    },
    // 是否显示基本信息
    getIconInfo() {
      this.isIconInfo = !this.isIconInfo;
    },
    //是否显示适用范围
    getIconRange() {
      this.isIconRange = !this.isIconRange;
    },
    //是否充值规则
    getIconRule() {
      this.isIconRule = !this.isIconRule;
    },
    //是否显示适用范围
    getIconDiscount() {
      this.isIconDiscount = !this.isIconDiscount;
    },
    //获取会员充值规则编号
    async getBillNo() {
      this.loading = true;
      const res = await getBillNo(this.billType);
      this.fillRuleForm.billNo = res;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wholeBody {
  background-color: rgb(230, 234, 238);
  min-height: 100vh;
  padding: 0px 20px 20px 20px;

  .addBtn {
    width: 100%;
    text-align: right;
    padding: 10px 0;
  }
  //级别折扣
  .leveDiscount {
    margin-bottom: 20px;
    .discountBody {
      padding: 20px 0 10px 0;
      .addLevelItem {
        margin-bottom: 20px;
      }
      .addLevel {
        cursor: pointer;
        color: #2386ee;
        margin-left: 80px;
      }
    }
  }
  //充值规则
  .giveRule {
    margin-bottom: 20px;
    .multiplyTitle {
      padding: 20px 0 10px 0;
      .checkboxTitle {
        margin-left: 7px;
        font-size: 14px;
      }
    }
    //充值规则列表
    .fillRuleListTable {
      display: flex;
      cursor: pointer;
      .fillRuleList {
        padding: 7px;
        width: 200px;
        height: 120px;
        font-size: 14px;
        margin-right: 24px;
        visibility: visible;
        border: 1px solid #e6e6e6;
        .fillRuleListAdd {
          color: #2386ee;
          display: flex;
          flex-direction: row;
          line-height: 110px;
          align-items: center;
          justify-content: center;
        }
        //删除图标
        .fillRuleListMoney {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      //充值规则列表获得焦点
      .focusfillRuleList {
        position: relative;
        padding: 7px;
        width: 200px;
        height: 120px;
        font-size: 14px;
        margin-right: 24px;
        visibility: visible;
        border: 1px solid #017ee1;

        &::before {
          position: absolute;
          content: "";
          right: 0;
          bottom: 0;
          border-right: 13px solid #2386ee;
          border-left: 13px solid transparent;
          border-top: 11px solid transparent;
          border-bottom: 11px solid #2386ee;
        }
        &::after {
          position: absolute;
          content: "";
          width: 10px;
          height: 6px;
          background-color: transparent;
          right: 3px;
          bottom: 5px;
          border-left: 2px solid #fff;
          border-bottom: 2px solid #fff;
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        .fillRuleListMoney {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
    //充值规则详情
    .RuleListdetail {
      display: flex;
      align-items: center;
      margin: 20px 0;
      height: 28px;
      //指定商品
      .appointGoods {
        height: 28px;
        line-height: 28px;
        margin-left: 20px;
      }
      //赠送金额多选框
      .giveMoneyRadio {
        margin: 0 20px;
      }
      //多选框
      .el-checkbox {
        margin-right: 0;
      }
      span {
        font-size: 14px;
      }
      //充值规则详情
      .fillRuleinput {
        width: 150px;
        margin-left: 20px;
        margin-right: 10px;
      }
      //赠送金额
      .fillRuleinputMoney {
        width: 150px;
        margin-right: 10px;
        margin-left: 10px;
      }

      //详情标题
      .checkboxTitle {
        margin-left: 7px;
        margin-right: 5px;
      }
    }
  }
  //适用范围标题
  .Rangetitle {
    margin-bottom: 20px;
  }
  //适用范围
  .bodyRange {
    //适用会员
    .rangeVip {
      margin: 20px 0;
      .el-button {
        margin-bottom: 10px;
      }
    }
    span {
      font-size: 14px;
      margin-right: 40px;
    }
  }
  //基本信息
  .baseInfo {
    margin-bottom: 20px;
    //未审核图标
    .nav-noAudit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/noAudit.png) no-repeat;
        background-size: 100px 43px;
        top: calc(100% - 32px);
        right: calc(1.5% - 80px);
      }
    }

    //已审核图标
    .nav-audit-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/audit.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 32px);
        right: calc(6% - 80px);
      }
    }
    //已禁用图标
    .nav-disable-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 100px;
        background: url(~@/assets/images/disable.png) no-repeat;
        background-size: 90px 43px;
        top: calc(100% - 32px);
        right: calc(6% - 80px);
      }
    }

    //已删除图标
    .nav-delete-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/delete.png) no-repeat;
        background-size: 100px 43px;
        top: calc(100% - 32px);
        right: calc(1.5% - 80px);
      }
    }

    //已关闭图标
    .nav-stop-icon {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        height: 64px;
        width: 165px;
        background: url(~@/assets/images/stop.png) no-repeat;
        background-size: 100px 43px;
        top: calc(100% - 32px);
        right: calc(1.5% - 80px);
      }
    }
    .baseInfoBody {
      margin-top: 20px;
      display: flex;
      .baseInput {
        width: 200px;
        margin: 0 20px 0 10px;
      }
      .remarkinput {
        width: 300px;
      }
    }
    //折扣优先级
    .discountUpSelect {
      width: 110px !important;
    }
    //大于
    .greater {
      font-size: 20px;
      width: 50px;
      height: 35px;
      line-height: 35px;
      text-align: center;
    }
  }
  //标题
  .titleRule {
    font-size: 16px;
    margin-left: 15px;
  }
  //标题图标
  .iconRule {
    color: #ccc;
  }
}

//多选框
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
//输入框
::v-deep .el-input__inner {
  height: 30px;
}
//选择商品
::v-deep .el-button--medium {
  padding: 6px 20px;
}
</style>
