var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wholeBody",
    },
    [
      _c(
        "div",
        { staticClass: "addBtn" },
        [
          _c(
            "el-button",
            {
              staticClass: "marR15",
              attrs: { type: "primary", disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  return _vm.submitForm(true)
                },
              },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              staticClass: "marR15",
              attrs: { type: "primary", disabled: _vm.disabled },
              on: { click: _vm.AddBill },
            },
            [_vm._v("保存并新增")]
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "marR15",
              attrs: {
                "split-button": "",
                type: "primary",
                size: "mini",
                icon: "el-icon-circle-check",
                disabled: _vm.showAudit,
              },
              on: {
                click: function ($event) {
                  return _vm.auditBill("审核")
                },
                command: _vm.auditBill,
              },
            },
            [
              _vm._v(" 审核 "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "反审核" } }, [
                    _vm._v("反审核"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "marR15",
              attrs: {
                "split-button": "",
                type: "success",
                size: "mini",
                icon: "el-icon-circle-check",
                disabled: _vm.Enable,
              },
              on: {
                click: function ($event) {
                  return _vm.getEnable(0)
                },
                command: function ($event) {
                  return _vm.getEnable(1)
                },
              },
            },
            [
              _vm._v(" 启用 "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "禁用" } }, [
                    _vm._v("禁用"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "marR15",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c("el-card", { staticClass: "baseInfo" }, [
        _c(
          "div",
          {
            staticClass: "bill-nav",
            class: {
              "nav-audit-icon": _vm.auditOrder,
              "nav-stop-icon": _vm.stopOrder,
              "nav-noAudit-icon": _vm.noAuditOrder,
              "nav-delete-icon": _vm.deleteOrder,
              "nav-disable-icon": _vm.disableOrder,
            },
          },
          [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isIconInfo,
                  expression: "isIconInfo",
                },
              ],
              staticClass: "el-icon-caret-right iconRule",
              on: { click: _vm.getIconInfo },
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isIconInfo,
                  expression: "!isIconInfo",
                },
              ],
              staticClass: "el-icon-caret-bottom iconRule",
              on: { click: _vm.getIconInfo },
            }),
            _c("span", { staticClass: "titleRule" }, [_vm._v("基本信息")]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isIconInfo,
                expression: "!isIconInfo",
              },
            ],
          },
          [
            _c(
              "el-form",
              {
                ref: "form",
                staticClass: "baseInfoBody",
                attrs: { model: _vm.fillRuleForm, rules: _vm.rules },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "billNo",
                      label: "方案编号",
                      "label-width": "80px",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "baseInput",
                      attrs: { disabled: true, placeholder: "方案编号" },
                      model: {
                        value: _vm.fillRuleForm.billNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.fillRuleForm, "billNo", $$v)
                        },
                        expression: "fillRuleForm.billNo",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      prop: "billRuleName",
                      label: "方案名称",
                      "label-width": "80px",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "baseInput",
                      attrs: {
                        disabled: _vm.disabled,
                        placeholder: "方案名称",
                      },
                      model: {
                        value: _vm.fillRuleForm.billRuleName,
                        callback: function ($$v) {
                          _vm.$set(_vm.fillRuleForm, "billRuleName", $$v)
                        },
                        expression: "fillRuleForm.billRuleName",
                      },
                    }),
                  ],
                  1
                ),
                _vm.isPriority
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "优先级", "label-width": "80px" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "discountUpSelect",
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.fillRuleForm.discountPriority1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm,
                                  "discountPriority1",
                                  $$v
                                )
                              },
                              expression: "fillRuleForm.discountPriority1",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.vip_discount_priority,
                            function (dict) {
                              return _c("el-option", {
                                key: dict.value,
                                attrs: { label: dict.label, value: dict.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isPriority
                  ? _c("span", { staticClass: "greater" }, [_vm._v(">")])
                  : _vm._e(),
                _vm.isPriority
                  ? _c(
                      "el-form-item",
                      { attrs: { prop: "discountPriority1" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "discountUpSelect",
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.fillRuleForm.discountPriority2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm,
                                  "discountPriority2",
                                  $$v
                                )
                              },
                              expression: "fillRuleForm.discountPriority2",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.vip_discount_priority,
                            function (dict) {
                              return _c("el-option", {
                                key: dict.value,
                                attrs: { label: dict.label, value: dict.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.dateTimeForm
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "datetime",
                          label: "有效日期",
                          "label-width": "80px",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.datetime,
                            callback: function ($$v) {
                              _vm.datetime = $$v
                            },
                            expression: "datetime",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "方案备注", "label-width": "80px" } },
                  [
                    _c("el-input", {
                      staticClass: "remarkinput",
                      attrs: {
                        disabled: _vm.disabled,
                        type: "textarea",
                        placeholder: "备注长度介于 1 和 80 字符之间",
                        maxlength: "80",
                        autosize: { minRows: 2, maxRows: 4 },
                      },
                      model: {
                        value: _vm.fillRuleForm.billRemark,
                        callback: function ($$v) {
                          _vm.$set(_vm.fillRuleForm, "billRemark", $$v)
                        },
                        expression: "fillRuleForm.billRemark",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.ChargeGive
        ? _c("el-card", { staticClass: "giveRule" }, [
            _c("div", [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isIconRule,
                    expression: "isIconRule",
                  },
                ],
                staticClass: "el-icon-caret-right iconRule",
                on: { click: _vm.getIconRule },
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isIconRule,
                    expression: "!isIconRule",
                  },
                ],
                staticClass: "el-icon-caret-bottom iconRule",
                on: { click: _vm.getIconRule },
              }),
              _c("span", { staticClass: "titleRule" }, [
                _vm._v("充值赠送规则"),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isIconRule,
                    expression: "!isIconRule",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticClass: "multiplyTitle" },
                  [
                    _c("el-checkbox", {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.fillRuleForm.isCanDiscount,
                        callback: function ($$v) {
                          _vm.$set(_vm.fillRuleForm, "isCanDiscount", $$v)
                        },
                        expression: "fillRuleForm.isCanDiscount",
                      },
                    }),
                    _c("span", { staticClass: "checkboxTitle" }, [
                      _vm._v(
                        " 倍增（启用倍增后，优先匹配更高金额的赠送规则，高金额未达标时，匹配低一级金额的赠送规则进行倍增） "
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "fillRuleListTable" },
                  [
                    _vm._l(
                      _vm.fillRuleForm.largessItems,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "fillRuleList",
                            class: {
                              focusfillRuleList: _vm.clickItem == index,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.focusRuleList(index)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "fillRuleListMoney" }, [
                              _c("span", [
                                _vm._v(
                                  " 充值金额：" + _vm._s(item.fillMoney) + "元"
                                ),
                              ]),
                              _c("i", {
                                staticClass: "el-icon-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteRuleList(index)
                                  },
                                },
                              }),
                            ]),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.largessMoney,
                                    expression: "item.largessMoney",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " 赠送金额：" +
                                    _vm._s(item.largessMoney) +
                                    "元 "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      item.largessGoodsItems.length &&
                                      item.largessGoodsItems.length > 0,
                                    expression:
                                      "\n              item.largessGoodsItems.length &&\n              item.largessGoodsItems.length > 0\n            ",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " 赠送商品:" +
                                    _vm._s(item.largessGoodsItems.length) +
                                    " 种 "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.largessScore,
                                    expression: "item.largessScore",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  " 赠送积分:" +
                                    _vm._s(item.largessScore) +
                                    " 分 "
                                ),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "fillRuleList",
                        on: { click: _vm.getRuleListAdd },
                      },
                      [
                        _c("span", { staticClass: "fillRuleListAdd" }, [
                          _vm._v("+添加"),
                        ]),
                      ]
                    ),
                  ],
                  2
                ),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "RuleListdetail" },
                    [
                      _c("span", [_vm._v("充值金额:")]),
                      _c("el-input", {
                        staticClass: "fillRuleinput",
                        model: {
                          value:
                            _vm.fillRuleForm.largessItems[_vm.clickItem]
                              .fillMoney,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.fillRuleForm.largessItems[_vm.clickItem],
                              "fillMoney",
                              _vm._n($$v)
                            )
                          },
                          expression:
                            "fillRuleForm.largessItems[clickItem].fillMoney",
                        },
                      }),
                      _c("span", [_vm._v("元")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "RuleListdetail" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.giveMoney,
                          callback: function ($$v) {
                            _vm.giveMoney = $$v
                          },
                          expression: "giveMoney",
                        },
                      }),
                      _c("span", { staticClass: "checkboxTitle" }, [
                        _vm._v("赠送金额"),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.giveMoney,
                              expression: "giveMoney",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticClass: "giveMoneyRadio",
                              attrs: { label: "1", disabled: _vm.disabled },
                              model: {
                                value: _vm.radioGiveMoney,
                                callback: function ($$v) {
                                  _vm.radioGiveMoney = $$v
                                },
                                expression: "radioGiveMoney",
                              },
                            },
                            [_vm._v("自定义")]
                          ),
                          _c("el-input", {
                            staticClass: "fillRuleinputMoney",
                            attrs: { disabled: _vm.isGiveMoneyInput },
                            model: {
                              value:
                                _vm.fillRuleForm.largessItems[_vm.clickItem]
                                  .largessMoney,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm.largessItems[_vm.clickItem],
                                  "largessMoney",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "\n                fillRuleForm.largessItems[clickItem].largessMoney\n              ",
                            },
                          }),
                          _c("span", [_vm._v("元")]),
                          _c(
                            "el-radio",
                            {
                              staticClass: "giveMoneyRadio",
                              attrs: { disabled: _vm.disabled, label: "2" },
                              model: {
                                value: _vm.radioGiveMoney,
                                callback: function ($$v) {
                                  _vm.radioGiveMoney = $$v
                                },
                                expression: "radioGiveMoney",
                              },
                            },
                            [_vm._v("充多少送多少")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "RuleListdetail" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.giveGoods,
                          callback: function ($$v) {
                            _vm.giveGoods = $$v
                          },
                          expression: "giveGoods",
                        },
                      }),
                      _c("span", { staticClass: "checkboxTitle" }, [
                        _vm._v("赠送商品"),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.giveGoods,
                              expression: "giveGoods",
                            },
                          ],
                          staticClass: "appointGoods",
                        },
                        [
                          _c("el-checkbox", {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.appointGoods,
                              callback: function ($$v) {
                                _vm.appointGoods = $$v
                              },
                              expression: "appointGoods",
                            },
                          }),
                          _c("span", { staticClass: "checkboxTitle" }, [
                            _vm._v("指定商品任选"),
                          ]),
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.appointGoods,
                                expression: "appointGoods",
                              },
                            ],
                            staticClass: "fillRuleinputMoney",
                            attrs: {
                              disabled: _vm.disabled,
                              oninput: "value=value.replace(/[^\\d]/g,'')",
                            },
                            model: {
                              value:
                                _vm.fillRuleForm.largessItems[_vm.clickItem]
                                  .largessGoodsCount,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm.largessItems[_vm.clickItem],
                                  "largessGoodsCount",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "\n                fillRuleForm.largessItems[clickItem].largessGoodsCount\n              ",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.appointGoods,
                                  expression: "appointGoods",
                                },
                              ],
                            },
                            [_vm._v("种")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { disabled: _vm.disabled },
                              on: { click: _vm.getGoodsFrame },
                            },
                            [_vm._v("选择商品")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.giveGoods
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingGoods,
                                  expression: "loadingGoods",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                "tooltip-effect": "dark",
                                data: _vm.fillRuleForm.largessItems[
                                  _vm.clickItem
                                ].largessGoodsItems,
                                border: "",
                                "max-height": "400",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "序号",
                                  align: "center",
                                  prop: "userId",
                                  width: "80",
                                  type: "index",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": "",
                                  label: "商品编码",
                                  align: "center",
                                  prop: "goodsNo",
                                  width: "155",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": "",
                                  label: "商品名称",
                                  align: "center",
                                  prop: "goodsName",
                                  width: "130",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品条码",
                                  align: "center",
                                  prop: "barcode",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  "show-overflow-tooltip": "",
                                  label: "规格",
                                  align: "center",
                                  prop: "goodsSpec",
                                  width: "130",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单位",
                                  align: "center",
                                  prop: "unitId",
                                  width: "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { disabled: _vm.disabled },
                                              model: {
                                                value: scope.row.unitId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "unitId",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.unitId",
                                              },
                                            },
                                            _vm._l(
                                              scope.row.units,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.unitId,
                                                  attrs: {
                                                    label: item.unitName,
                                                    value: item.unitId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1489295457
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品类别",
                                  align: "center",
                                  prop: "categoryName",
                                  width: "155",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "价格",
                                  align: "center",
                                  prop: "salePrice",
                                  width: "120",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "数量",
                                  align: "center",
                                  width: "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            attrs: { disabled: _vm.disabled },
                                            model: {
                                              value: scope.row.goodsQty,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "goodsQty",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "scope.row.goodsQty",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  551415839
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  fixed: "right",
                                  label: "操作",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.deleteRowGoods(
                                                    scope.$index,
                                                    _vm.fillRuleForm
                                                      .largessItems[
                                                      _vm.clickItem
                                                    ].largessGoodsItems
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 移除 ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  297660885
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "RuleListdetail" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.giveCoupon,
                          callback: function ($$v) {
                            _vm.giveCoupon = $$v
                          },
                          expression: "giveCoupon",
                        },
                      }),
                      _c("span", { staticClass: "checkboxTitle" }, [
                        _vm._v("赠送优惠券"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "RuleListdetail" },
                    [
                      _c("el-checkbox", {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.giveScore,
                          callback: function ($$v) {
                            _vm.giveScore = $$v
                          },
                          expression: "giveScore",
                        },
                      }),
                      _c("span", { staticClass: "checkboxTitle" }, [
                        _vm._v("赠送积分"),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.giveScore,
                              expression: "giveScore",
                            },
                          ],
                        },
                        [
                          _c("el-input", {
                            staticClass: "fillRuleinputMoney",
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value:
                                _vm.fillRuleForm.largessItems[_vm.clickItem]
                                  .largessScore,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.fillRuleForm.largessItems[_vm.clickItem],
                                  "largessScore",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "\n                fillRuleForm.largessItems[clickItem].largessScore\n              ",
                            },
                          }),
                          _c("span", [_vm._v("积分")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.isLevelDiscount
        ? _c("el-card", { staticClass: "leveDiscount" }, [
            _c("div", [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isIconDiscount,
                    expression: "isIconDiscount",
                  },
                ],
                staticClass: "el-icon-caret-right iconRule",
                on: { click: _vm.getIconDiscount },
              }),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isIconDiscount,
                    expression: "!isIconDiscount",
                  },
                ],
                staticClass: "el-icon-caret-bottom iconRule",
                on: { click: _vm.getIconDiscount },
              }),
              _c("span", { staticClass: "titleRule" }, [
                _vm._v("会员级别折扣"),
              ]),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isIconDiscount,
                    expression: "!isIconDiscount",
                  },
                ],
                staticClass: "discountBody",
              },
              [
                _vm._l(_vm.fillRuleForm.vipLevelItems, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "x-f addLevelItem" },
                    [
                      _c("span", { staticClass: "marR15" }, [
                        _vm._v("会员级别"),
                      ]),
                      _c("SelectFrame", {
                        ref: "selectFrame",
                        refInFor: true,
                        attrs: {
                          tableData: _vm.vipLevelData,
                          disabled: _vm.disabled,
                          router: "/vipcard/base/level",
                          normalizer: _vm.normalizerVipLevel,
                        },
                        on: {
                          getPitchId: function ($event) {
                            return _vm.getDiyselectId($event, index)
                          },
                          click: function ($event) {
                            _vm.OpenVipLeve = true
                          },
                        },
                        model: {
                          value: item.vipLevelName,
                          callback: function ($$v) {
                            _vm.$set(item, "vipLevelName", $$v)
                          },
                          expression: "item.vipLevelName",
                        },
                      }),
                      _c("span", { staticClass: "marR15 marL15" }, [
                        _vm._v("折扣(%)"),
                      ]),
                      _c("el-input", {
                        staticClass: "inputWidth",
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: item.discountRate,
                          callback: function ($$v) {
                            _vm.$set(item, "discountRate", _vm._n($$v))
                          },
                          expression: "item.discountRate",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            type: "text",
                            size: "small",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteRowVipLevel(
                                index,
                                _vm.fillRuleForm.vipLevelItems
                              )
                            },
                          },
                        },
                        [_vm._v(" 删除 ")]
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  {
                    staticClass: "marT15 addLevel",
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.GetAddLevel },
                  },
                  [_vm._v(" +级别 ")]
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c("el-card", [
        _c("div", { staticClass: "Rangetitle" }, [
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isIconRange,
                expression: "isIconRange",
              },
            ],
            staticClass: "el-icon-caret-right iconRule",
            on: { click: _vm.getIconRange },
          }),
          _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isIconRange,
                expression: "!isIconRange",
              },
            ],
            staticClass: "el-icon-caret-bottom iconRule",
            on: { click: _vm.getIconRange },
          }),
          _c("span", { staticClass: "titleRule" }, [_vm._v("适用范围")]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isIconRange,
                expression: "!isIconRange",
              },
            ],
            staticClass: "bodyRange",
          },
          [
            _vm.isGiveRule
              ? _c(
                  "div",
                  { staticClass: "rangeVip" },
                  [
                    _c("span", [_vm._v("适用会员")]),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.fillRuleForm.vipLevelRangeType,
                          callback: function ($$v) {
                            _vm.$set(_vm.fillRuleForm, "vipLevelRangeType", $$v)
                          },
                          expression: "fillRuleForm.vipLevelRangeType",
                        },
                      },
                      _vm._l(
                        _vm.dict.type.fill_vip_level_range_type,
                        function (dict) {
                          return _c(
                            "el-radio",
                            { key: dict.value, attrs: { label: dict.value } },
                            [_vm._v(_vm._s(dict.label))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.fillRuleForm.vipLevelRangeType == "2" ||
                              _vm.fillRuleForm.vipLevelRangeType == "1",
                            expression:
                              "\n            fillRuleForm.vipLevelRangeType == '2' ||\n            fillRuleForm.vipLevelRangeType == '1'\n          ",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: { click: _vm.getVipLeve },
                          },
                          [_vm._v("指定会员等级")]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loadingVip,
                                    expression: "loadingVip",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "tooltip-effect": "dark",
                                  data: _vm.fillRuleForm.vipLevelItems,
                                  border: "",
                                  "max-height": "400",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "序号",
                                    align: "center",
                                    prop: "userId",
                                    width: "80",
                                    type: "index",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    label: "会员级别编号",
                                    align: "center",
                                    prop: "vipLevelNo",
                                    width: "180",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    "show-overflow-tooltip": "",
                                    label: "会员级别名称",
                                    align: "center",
                                    prop: "vipLevelName",
                                    width: "180",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "isUse",
                                    label: "排除",
                                    width: "120",
                                    "show-overflow-tooltip": true,
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": 1,
                                                "inactive-value": 0,
                                              },
                                              model: {
                                                value: scope.row.isExcl,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "isExcl",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.isExcl",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1647492927
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    fixed: "right",
                                    label: "操作",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.deleteRowVips(
                                                      scope.$index,
                                                      _vm.fillRuleForm
                                                        .vipLevelItems
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 移除 ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    404476687
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "rangeShop" },
              [
                _c("span", [_vm._v("适用门店")]),
                _c(
                  "el-radio-group",
                  {
                    attrs: { disabled: _vm.disabled },
                    model: {
                      value: _vm.fillRuleForm.shopRangeType,
                      callback: function ($$v) {
                        _vm.$set(_vm.fillRuleForm, "shopRangeType", $$v)
                      },
                      expression: "fillRuleForm.shopRangeType",
                    },
                  },
                  _vm._l(_vm.dict.type.fill_shop_range_type, function (dict) {
                    return _c(
                      "el-radio",
                      { key: dict.value, attrs: { label: dict.value } },
                      [_vm._v(_vm._s(dict.label))]
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.fillRuleForm.shopRangeType == "2" ||
                          _vm.fillRuleForm.shopRangeType == "1",
                        expression:
                          "\n            fillRuleForm.shopRangeType == '2' ||\n            fillRuleForm.shopRangeType == '1'\n          ",
                      },
                    ],
                    staticClass: "rangeVip",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { disabled: _vm.disabled },
                        on: { click: _vm.getShopInfo },
                      },
                      [_vm._v("指定门店资料")]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingShopInfo,
                                expression: "loadingShopInfo",
                              },
                            ],
                            staticStyle: { width: "100%" },
                            attrs: {
                              "tooltip-effect": "dark",
                              data: _vm.fillRuleForm.shopItems,
                              border: "",
                              "max-height": "400",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                prop: "userId",
                                width: "80",
                                type: "index",
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopNo",
                              attrs: {
                                label: "门店编码",
                                width: "160",
                                align: "center",
                                prop: "shopNo",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopName",
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopTypeName",
                              attrs: {
                                label: "门店类型",
                                width: "130",
                                align: "center",
                                prop: "shopTypeName",
                                "show-overflow-tooltip": true,
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "isUse",
                                label: "排除",
                                width: "120",
                                "show-overflow-tooltip": true,
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("el-switch", {
                                        attrs: {
                                          "active-value": 1,
                                          "inactive-value": 0,
                                        },
                                        model: {
                                          value: scope.row.isExcl,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "isExcl", $$v)
                                          },
                                          expression: "scope.row.isExcl",
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                fixed: "right",
                                label: "操作",
                                align: "center",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteRowShopInfoS(
                                                scope.$index,
                                                _vm.fillRuleForm.shopItems
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 移除 ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.rangeGoods
              ? _c(
                  "div",
                  { staticClass: "rangeVip" },
                  [
                    _c("span", [_vm._v("适用商品")]),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.fillRuleForm.goodsRangeType,
                          callback: function ($$v) {
                            _vm.$set(_vm.fillRuleForm, "goodsRangeType", $$v)
                          },
                          expression: "fillRuleForm.goodsRangeType",
                        },
                      },
                      _vm._l(
                        _vm.dict.type.vip_goods_range_type,
                        function (dict) {
                          return _c(
                            "el-radio",
                            { key: dict.value, attrs: { label: dict.value } },
                            [_vm._v(_vm._s(dict.label))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.fillRuleForm.goodsRangeType == "2" ||
                              _vm.fillRuleForm.goodsRangeType == "1",
                            expression:
                              "\n            fillRuleForm.goodsRangeType == '2' ||\n            fillRuleForm.goodsRangeType == '1'\n          ",
                          },
                        ],
                        staticClass: "rangeVip",
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: { click: _vm.getGoodsFrame },
                          },
                          [_vm._v("指定商品")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: { click: _vm.getOpenBrand },
                          },
                          [_vm._v("指定品牌")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: { click: _vm.getOpenCategory },
                          },
                          [_vm._v("指定类别")]
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loadingBrand,
                                    expression: "loadingBrand",
                                  },
                                ],
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "tooltip-effect": "dark",
                                  data: _vm.fillRuleForm.goodsItems,
                                  border: "",
                                  "max-height": "400",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "序号",
                                    align: "center",
                                    prop: "userId",
                                    width: "80",
                                    type: "index",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "类型",
                                    align: "center",
                                    prop: "lineType",
                                    width: "123",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("dict-tag", {
                                              attrs: {
                                                options:
                                                  _vm.dict.type
                                                    .vip_applicable_commodities_appoint_type,
                                                value: scope.row.lineType,
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1794384631
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "编号",
                                    align: "center",
                                    prop: "lineNo",
                                    width: "180",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "名称",
                                    align: "center",
                                    prop: "lineName",
                                    width: "180",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "规格",
                                    align: "center",
                                    prop: "goodsSpec",
                                    width: "180",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "isUse",
                                    label: "排除",
                                    width: "120",
                                    "show-overflow-tooltip": true,
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("el-switch", {
                                              attrs: {
                                                "active-value": 1,
                                                "inactive-value": 0,
                                              },
                                              model: {
                                                value: scope.row.isExcl,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "isExcl",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.isExcl",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1647492927
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    fixed: "right",
                                    label: "操作",
                                    align: "center",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.deleteRowgoods(
                                                      scope.$index,
                                                      _vm.fillRuleForm
                                                        .goodsItems
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(" 移除 ")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3375250954
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
      _c("SelectGoods", {
        attrs: { OpenGoods: _vm.OpenGoods },
        on: {
          "update:OpenGoods": function ($event) {
            _vm.OpenGoods = $event
          },
          "update:open-goods": function ($event) {
            _vm.OpenGoods = $event
          },
          getGoodS: _vm.getGoodS,
        },
      }),
      _c("vipLeve", {
        attrs: { OpenVipLeve: _vm.OpenVipLeve },
        on: {
          "update:OpenVipLeve": function ($event) {
            _vm.OpenVipLeve = $event
          },
          "update:open-vip-leve": function ($event) {
            _vm.OpenVipLeve = $event
          },
          vipLeveList: _vm.vipLeveList,
        },
      }),
      _c("shopInfo", {
        attrs: { OpenShopInfo: _vm.OpenShopInfo },
        on: {
          "update:OpenShopInfo": function ($event) {
            _vm.OpenShopInfo = $event
          },
          "update:open-shop-info": function ($event) {
            _vm.OpenShopInfo = $event
          },
          shopInfoList: _vm.shopInfoList,
        },
      }),
      _c("SelectBrand", {
        attrs: { OpenBrand: _vm.OpenBrand },
        on: {
          "update:OpenBrand": function ($event) {
            _vm.OpenBrand = $event
          },
          "update:open-brand": function ($event) {
            _vm.OpenBrand = $event
          },
          brandList: _vm.brandList,
        },
      }),
      _c("selectCategory", {
        attrs: { OpenCategory: _vm.OpenCategory },
        on: {
          "update:OpenCategory": function ($event) {
            _vm.OpenCategory = $event
          },
          "update:open-category": function ($event) {
            _vm.OpenCategory = $event
          },
          categoryList: _vm.categoryList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }