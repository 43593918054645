<template>
  <div class="y-start">
    <el-form
        ref="form"
        :model="form"
        label-width="100px"
        label-position="right"
        :rules="rules"
    >
      <div class="x-w">
        <el-form-item label="单据日期">
          <el-date-picker
              v-model="billtime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="20px">
          <el-radio-group v-model="params.filterTime" size="medium">
            <el-radio-button label="today" style="max-width: 70px"
            >今天
            </el-radio-button
            >
            <el-radio-button label="yesterday" style="max-width: 70px"
            >昨天
            </el-radio-button
            >
            <el-radio-button label="thisWeek" style="max-width: 70px"
            >本周
            </el-radio-button
            >
            <el-radio-button label="lastWeek" style="max-width: 70px"
            >上周
            </el-radio-button
            >
            <el-radio-button label="thisMonth" style="max-width: 70px"
            >本月
            </el-radio-button
            >
            <el-radio-button label="lastMonth" style="max-width: 70px"
            >上月
            </el-radio-button
            >
            <el-radio-button label="thisSeason" style="max-width: 70px"
            >本季
            </el-radio-button
            >
            <el-radio-button label="lastSeason" style="max-width: 70px"
            >上季
            </el-radio-button
            >
            <el-radio-button label="thisYear" style="max-width: 70px"
            >本年
            </el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="20px" v-if="isHighSearch">
          <el-button
              type="primary"
              :icon="icon"
              size="mini"
              @click="showHighSearch"
          >高级搜索
          </el-button>
        </el-form-item>
        <el-form-item label-width="20px">
          <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="searchBtn"
          >搜索
          </el-button>
        </el-form-item>
      </div>
      <slot name="HighSearch" v-if="showHigh && isHighSearch"></slot>
    </el-form>
  </div>
</template>

<script>
import CTable from '@/views/components/c-table/index.vue'

export default {
  name: 'vipSearchTitle2',
  props: {
    isHighSearch: {
      // 是否显示高级选项按钮
      type: Boolean,
      default: false
    },
    params:{
      type: Object,
    }
  },
  components: {
    CTable
  },
  data() {
    return {
      billtime: [],
      form: {
        beginBillDate: '',
        endBillDate: '',
        filterTime: ''
      },
      rules: {},
      icon: 'el-icon-caret-top',
      // multipleSelection: [], // table已选择的数据
      tableRadio: {}, // table 单选的数据
      loading: false,
      showHigh: false, // 是否显示高级搜索
    }
  },
  methods: {
    //是否显示高级搜索
    showHighSearch() {
      this.icon =
          this.icon === 'el-icon-caret-top'
              ? 'el-icon-caret-bottom'
              : 'el-icon-caret-top'
      this.$emit('HighSearch')
      this.showHigh = !this.showHigh
    },
    // 调教表单
    searchBtn() {
      let type = 0
      if(JSON.stringify(this.billtime) != '{}'){
        type = 1
        this.params.beginBillDate = this.billtime[0]
        this.params.endBillDate = this.billtime[1]
      }
      if(this.form.filterTime != ''){
        type = 2
      }

      this.$emit('searchData', type, this.params)
    }
  }
}
</script>

<style scoped>
.el-radio-group {
  width: 630px;
}

/* {*/
/*  width: 200px;*/
/*}*/
/*/deep/ .el-input__inner,*/
/*::v-deep.el-textarea__inner {*/
/*  width: 200px;*/
/*}*/
</style>
