var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form-item",
    { attrs: { prop: "videoPath" } },
    [
      _c(
        "el-upload",
        {
          ref: "video",
          staticClass: "upload-demo",
          attrs: {
            action: _vm.url,
            name: "video",
            drag: "",
            multiple: false,
            "before-upload": _vm.beforeUploadVideo,
            "on-success": _vm.handleUploadSuccess,
          },
          model: {
            value: _vm.form.videoPath,
            callback: function ($$v) {
              _vm.$set(_vm.form, "videoPath", $$v)
            },
            expression: "form.videoPath",
          },
        },
        [
          _c("i", { staticClass: "el-icon-upload" }),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v(" 将文件拖到此处，或"),
            _c("em", [_vm._v("点击上传")]),
          ]),
          _c(
            "div",
            {
              staticClass: "el-upload__tip",
              attrs: { slot: "tip" },
              slot: "tip",
            },
            [
              _vm._v(
                "注：视频格式仅支持mp4、ogg、flv、avi、wmv、rmvb，且大小不能超过50MB"
              ),
            ]
          ),
        ]
      ),
      _c("el-progress", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.progressFlag,
            expression: "progressFlag",
          },
        ],
        attrs: { percentage: _vm.loadProgress },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }