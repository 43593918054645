<template>
  <!-- ******个人中心********* -->
  <div class="wrap">
    <!-- 顶部按钮 -->
    <div class="btnTOP">
      <!-- @click="submitForm" -->
      <el-button type="primary" size="mini" @click="subPreserve"
        >保存
      </el-button>
      <!-- @click="getQuit" -->
      <el-button size="mini" @click="logout">退出</el-button>
    </div>
    <div class="x-start">
      <div class="leftBox padd15">
        <div
          class="item x-c cursorP"
          :class="{ clickStyle: leftItem == index }"
          v-for="(item, index) in leftBtnList"
          :key="index"
          @click="itemClick(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="rightBox marL10">
        <cardTitleCom
          style="height: 100%"
          cardTitle="个人信息"
          v-show="leftItem == 0"
        >
          <template #cardContent>
            <div class="padd15">
              <el-form
                :label-position="labelPosition"
                label-width="80px"
                :model="formLabelAlign"
                ref="form"
                :rules="rulesMessage"
              >
                <el-form-item class="avatar" label="头像">
                  <div class="userPic">
                    <div class="text-left">
                      <userAvatar class="marT20" />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="昵称">
                  <el-input
                    placeholder="用户昵称"
                    v-model="formLabelAlign.nickName"
                  ></el-input>
                </el-form-item>
                <el-form-item label="账号">
                  <el-input
                    v-model="formLabelAlign.userName"
                    disabled
                    placeholder="账号"
                  ></el-input>
                  <el-button type="text" @click="openDialog(1)" class="marL10"
                    >更改</el-button
                  >
                  <!-- ==身份验证== -->
                  <el-dialog
                    title="身份验证"
                    :visible.sync="dialogVisible"
                    width="520px"
                    v-dialogDrag="true"
                  >
                    <div class="idCenter">
                      <div class="marB20">
                        为了你的账户安全，请验证身份，验证成功后进行下一步损作。
                      </div>
                      <div class="telBox">
                        <div class="marL10 telNum">
                          {{ `使用手机${telephone}验证` }}
                        </div>
                      </div>
                      <el-input
                        placeholder="6位数字验证码"
                        class="code"
                        type="number"
                        v-model="code"
                        maxlength="6"
                      ></el-input>

                      <el-button
                        class="marL15"
                        type="primary"
                        @click="getMessage(1)"
                        :disabled="isDisabled"
                        >{{ messageInfo }}</el-button
                      >
                    </div>

                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="closeAndOpenNewDialog"
                        >验 证</el-button
                      >
                    </span>
                    <!-- ==更改手机号== -->
                  </el-dialog>
                  <el-dialog
                    title="更改手机号码"
                    :visible.sync="dialogNewTel"
                    width="520px"
                    v-dialogDrag="true"
                  >
                    <div class="idCenter">
                      <el-input
                        placeholder="请输入新的手机号码"
                        class="marB20"
                        @input="handleInput"
                        type="number"
                        v-model="newTel"
                        maxlength="11"
                      ></el-input>
                      <el-input
                        placeholder="6位数字验证码"
                        class="code"
                        type="number"
                        v-model="newCode"
                        maxlength="6"
                      ></el-input>
                      <el-button
                        class="marL15"
                        type="primary"
                        @click="getMessage(2)"
                        :disabled="isDisabledNew"
                        >{{ messageInfoNew }}</el-button
                      >
                    </div>
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="newTelVerify"
                        >验 证</el-button
                      >
                    </span>
                  </el-dialog>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input
                    v-model="formLabelAlign.email"
                    placeholder="请输入邮箱"
                  ></el-input>
                </el-form-item>
                <el-form-item label="性别">
                  <el-radio-group v-model="formLabelAlign.sex">
                    <el-radio label="0">男</el-radio>
                    <el-radio label="1">女</el-radio>
                    <el-radio label="2">未知</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="创建日期">
                  <el-input
                    disabled
                    placeholder="创建日期"
                    v-model="formLabelAlign.createTime"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
        </cardTitleCom>
        <cardTitleCom
          style="height: 100%"
          cardTitle="修改密码"
          v-show="leftItem == 1"
        >
          <template #cardContent>
            <div class="padd15">
              <el-form
                :label-position="labelPosition"
                label-width="100px"
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                class="demo-ruleForm"
              >
                <el-form-item label="旧密码" prop="oldPassword">
                  <el-input
                    v-model="ruleForm.oldPassword"
                    placeholder="请输入旧密码"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="password">
                  <el-input
                    v-model="ruleForm.password"
                    placeholder="6~18位字母、数字、特殊字符密码"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="verifyPassword">
                  <el-input
                    v-model="ruleForm.verifyPassword"
                    placeholder="请确认新密码"
                    show-password
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </template>
        </cardTitleCom>
      </div>
    </div>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom/index.vue'
import userAvatar from './userAvatar.vue'
import {
  findByUserId,
  sendCode,
  smsVerifyCode,
  resetUserPwd,
  updateUser
} from '@/api/system/user'

import { mapMutations } from 'vuex'

export default {
  name: 'Profile',
  components: {
    cardTitleCom,
    userAvatar
  },
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.verifyPassword !== '') {
          this.$refs.ruleForm.validateField('verifyPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致 !'))
      } else {
        callback()
      }
    }
    return {
      newTel: undefined, //新手机号
      newCode: undefined, //新手机号验证码
      code: '', //修改账号验证
      leftBtnList: ['个人信息', '修改密码'],
      leftItem: 0, // 左边菜单点击项
      labelPosition: 'right',
      isSend: false, // 是否发送过短信
      // 发送短信验证码按钮文本
      messageInfo: '发送验证码',
      messageInfoNew: '发送验证码',
      isDisabledNew: false, // 是否可点击
      isDisabled: false, // 是否可点击
      isAuthCode: false, // 验证码是否正确
      formLabelAlign: {
        name: '',
        region: '',
        type: '',
        password: ''
      },
      telephone: '', //加密手机
      ruleForm: {
        password: ''
      }, //重置账号密码
      rulesMessage: {
        email: [
          {
            validator: (rule, value, callback) => {
              const mailReg =
                /([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
              if (!value) {
                // return callback(new Error('邮箱不能为空'))
                callback()
              }
              if (mailReg.test(value)) {
                callback()
              } else {
                callback(new Error('请输入正确的邮箱格式'))
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      },
      rules: {
        oldPassword: [
          { required: true, trigger: ['blur', 'change'], message: '请输入密码' }
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
            trigger: ['blur', 'change']
          },
          {
            min: 6,
            max: 18,
            message: '长度在 6 到 18 个字符',
            trigger: ['blur', 'change']
          },
          { validator: validatePass, trigger: ['blur', 'change'] }
        ],
        verifyPassword: [
          {
            required: true,
            message: '请确认密码',
            trigger: ['blur', 'change']
          },
          {
            min: 6,
            max: 18,
            message: '长度在 6 到 18 个字符',
            trigger: ['blur', 'change']
          },
          {
            validator: validatePass2,
            trigger: ['blur', 'change'],
            required: true
          }
        ]
      },
      dialogVisible: false, //身份验证弹窗
      dialogNewTel: false, //更改手机号弹窗
      dialogEmail: false, //更改邮箱弹窗
      radio: '',
      tel: '',
      dialogType: null
    }
  },
  async created () {
    const res = await findByUserId()
    console.log('商品', res)
    this.formLabelAlign = res.data
    //手机
    this.telephone = this.hidePhoneNumber(this.formLabelAlign.telephone)
  },
  methods: {
    ...mapMutations('tagsView', ['DEL_VISITED_VIEW']),

    //保存
    async subPreserve () {
      if (this.leftItem == 1) {
        //重置密码
        this.$refs.ruleForm.validate(async valid => {
          if (valid) {
            try {
              await resetUserPwd({
                ...this.ruleForm,
                userId: this.$store.state.user.userinfo.userId
              })
              this.$message({
                message: '保存成功',
                type: 'success'
              })
              this.$store.dispatch('LogOut').then(() => {
                location.href = '/index'
              })
            } catch (error) {
              // this.$message.error('保存失败')
            }
          }
        })
      } else {
        //修改个人信息
        this.$refs.form.validate(async valid => {
          if (valid) {
            try {
              await updateUser(this.formLabelAlign)
              this.$message({
                message: '保存成功',
                type: 'success'
              })
            } catch (error) {
              // this.$message.error('保存失败')
            }
          }
        })
      }
    },
    //加密手机
    hidePhoneNumber (phoneNumber) {
      const prefix = phoneNumber.substring(0, 3)
      const suffix = phoneNumber.substring(phoneNumber.length - 4)
      const middle = '*'.repeat(phoneNumber.length - 7)
      return `${prefix}${middle}${suffix}`
    },
    openDialog (type) {
      this.dialogType = type
      this.dialogVisible = true
    },
    itemClick (index) {
      this.leftItem = index
    },
    //新手机号验证
    async newTelVerify () {
      try {
        if (!this.newCode || !this.newTel)
          return this.$message.error('短信验证码或手机号不能为空')
        //验证
        await smsVerifyCode({
          telephone: this.newTel,
          code: this.newCode
        })
        //手机号
        this.formLabelAlign.telephone = this.newTel
        //验证码
        this.formLabelAlign.smsCode = this.newCode
        // 关闭弹窗
        this.dialogNewTel = false
      } catch (error) {
        if (error.status == 'fail') {
          //短信验证码错误，请重新输入
          this.$message.error('短信验证码错误，请重新输入')
        }
      }
    },
    //手机号验证
    async closeAndOpenNewDialog () {
      try {
        if (!this.code) return this.$message.error('短信验证码不能为空')
        //验证
        await smsVerifyCode({
          telephone: this.formLabelAlign.telephone,
          code: this.code
        })
        // 关闭第一个弹窗
        this.dialogVisible = false
        if (this.dialogType === 1) {
          // 打开更改手机账号弹窗
          this.dialogNewTel = true
        } else {
          // 邮箱弹窗
          this.dialogEmail = true
        }
      } catch (error) {
        if (error.status == 'fail') {
          //短信验证码错误，请重新输入
          this.$message.error('短信验证码错误，请重新输入')
        }
      }
    },
    handleInput (value) {
      let inputValue = String(value).slice(0, 11) // 截取前11个字符
      if (inputValue.length > 0 && isNaN(inputValue)) {
        // 如果输入的值不是有效的数字，则将其设为为空字符串
        inputValue = ''
      }
      this.tel = inputValue // 更新表单数据
    },
    // 发送短信验证码
    async getMessage (val) {
      // console.log("手机号：", this.form.telephone);
      // if (this.form.telephone == "" || this.form.telephone == undefined) {
      //   return this.$message.warning("请先输入手机号");
      // }
      // sendCode({
      //   telephone: this.form.telephone + "",
      // });

      // this.isSend = true // 标记已发送过短信
      if (val == 1) {
        this.isDisabled = true
        let time = 60
        this.messageInfo = time + '秒后重试'
        let timer = setInterval(async () => {
          if (time == 0) {
            clearInterval(timer)
            this.messageInfo = '获取验证码'
            this.isDisabled = false
          } else {
            this.messageInfo = time + '秒后重试'
            time = time - 1
          }
        }, 1000)
        //发送短信验证码
        await sendCode(this.formLabelAlign.telephone)
      } else if (val == 2) {
        if (!this.newTel) return this.$message.error('新手机号不能为空')
        this.isDisabledNew = true
        let time = 60
        this.messageInfoNew = time + '秒后重试'
        let timer = setInterval(async () => {
          if (time == 0) {
            clearInterval(timer)
            this.messageInfoNew = '获取验证码'
            this.isDisabledNew = false
          } else {
            this.messageInfoNew = time + '秒后重试'
            time = time - 1
          }
        }, 1000)
        //发送短信验证码
        await sendCode(this.newTel)
      }
    },
    // 验证验证码是否正确
    verifyCode (val) {
      console.log('验证码：', val.length)
      if (val.length == 6) {
        smsVerifyCode({
          telephone: this.form.telephone,
          code: val
        }).then(res => {
          if (res.status == 'fail') {
            this.isAuthCode = false
          } else {
            this.isAuthCode = true
          }
        })
      }
    },

    // 退出个人中心，并删除浏览器记录
    async logout () {
      this.$router.go(-1)
      const find = this.$store.getters.visitedViews.find(
        item => item.path === this.$route.path
      )
      this.DEL_VISITED_VIEW(find)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 6px 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding-bottom: 6px;
  }
  .leftBox {
    width: 210px;
    height: calc(100vh - 130px);
    background: #ffffff;
    .item {
      height: 50px;
      font-size: 16px;
      font-weight: 550;
    }
  }
  .rightBox {
    width: calc(100% - 220px);
    height: calc(100vh - 130px);
    // background: #ffffff;
  }
  // ::v-deep .el-card__body {
  //   height: calc(100vh - 150px);
  // }
}

.clickStyle {
  background: #e8f1fc;
}
.userPic {
  // border: 1px solid pink;
  text-align: left;
}

::v-deep .avatar .el-form-item__label {
  width: 80px;
  height: 135px;
  // border: 1px solid pink;
  display: flex;
  justify-content: right;
  align-items: center;
}

::v-deep .okAgain .el-button--medium {
  display: flex;
  justify-content: right;
  align-items: center;
}
.el-input {
  width: 371px;
  height: 42px;
  border-radius: 4px;
}
::v-deep input[disabled],
input:disabled,
input.disabled {
  -webkit-text-fill-color: #c0c4cc;
  background: #f5f7fa;
  -webkit-opacity: 1;
  opacity: 1;
  cursor: not-allowed;
}
.telBox {
  width: 371px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  margin-bottom: 20px;
}
.telNum {
  font-size: 15px;
  line-height: 38px;
}
.code {
  width: 243px;
}
.idCenter {
  // border: 1px solid palegoldenrod;
  width: 520px;
  border-bottom: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
  padding: 30px 25px 40px 25px;
}
::v-deep .el-dialog__body {
  padding: 0;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
/* 解决el-input设置类型为number时，中文输入法光标上移问题 */
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/* 解决el-input设置类型为number时，去掉输入框后面上下箭头 */
::v-deep input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
</style>

<!-- --------旧版页面--------------- -->
<!-- <template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="6" :xs="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <div>
            <div class="text-center">
              <userAvatar :user="user" />
            </div>
            <ul class="list-group list-group-striped">
              <li class="list-group-item">
                <svg-icon icon-class="user" />用户名称
                <div class="pull-right">{{ user.userName }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="phone" />手机号码
                <div class="pull-right">{{ user.telephone }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="email" />用户邮箱
                <div class="pull-right">{{ user.email }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="tree" />所属部门
                <div class="pull-right" v-if="user.dept">{{ user.dept.deptName }} / {{ postGroup }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="peoples" />所属角色
                <div class="pull-right">{{ roleGroup }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="date" />创建日期
                <div class="pull-right">{{ user.createTime }}</div>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18" :xs="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>基本资料</span>
          </div>
          <el-tabs v-model="activeTab">
            <el-tab-pane label="基本资料" name="userinfo">
              <userInfo :user="user" />
            </el-tab-pane>
            <el-tab-pane label="修改密码" name="resetPwd">
              <resetPwd :user="user" />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userAvatar from "./userAvatar";
import userInfo from "./userInfo";
import resetPwd from "./resetPwd";
import { getUserProfile } from "@/api/system/employee";

export default {
  name: "Profile",
  components: { userAvatar, userInfo, resetPwd },
  data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: "userinfo"
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      getUserProfile().then(response => {
        this.user = response.data;
        this.roleGroup = response.roleGroup;
        this.postGroup = response.postGroup;
      });
    }
  }
};
</script>
 -->
