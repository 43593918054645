<template>
  <div>
    <el-dialog
      :visible="OpenVipLeve"
      title="选择会员级别"
      :before-close="close"
      v-dialogDrag="true"
      width="40%"
    >
      <!-- 整个表格 -->
      <div class="vipLevetableCom" v-loading="loading">
        <div class="top">
          <!-- 搜索框 -->
          <div>
            <el-input
              class="searchInput"
              placeholder="请输入会员级别编号"
              prefix-icon="el-icon-search"
              v-model="queryParams.vipLevelNo"
              size="small"
              @keyup.enter.native="handleQuery"
            >
            </el-input>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
              >搜索</el-button
            >
          </div>
        </div>
        <el-table
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          border
          max-height="400"
          ref="multipleTable"
          @row-click="handleRowClick"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column align="center" type="index" width="80" label="序号">
          </el-table-column>
          <el-table-column
            align="center"
            prop="vipLevelNo"
            label="会员级别编号"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="vipLevelName"
            label="会员级别名称"
            show-overflow-tooltip
            align="center"
          >
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getConfirm" size="mini"
          >确 定</el-button
        >
        <el-button @click="close" size="mini">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listlevel } from '@/api/vip/base/level' //会员等级
export default {
  props: {
    //商品信息开关
    OpenVipLeve: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false, //加载
      total: 0, //条数
      tableData: [], //会员等级
      selectAllData: [], //选中数组
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        vipLevelName: null
      }
    }
  },

  watch: {
    OpenVipLeve (val) {
      if (val) {
        this.getList()
      } else {
        this.reset()
      }
    }
  },
  methods: {
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //确定按钮
    getConfirm () {
      if (this.selectAllData.length >= 1)
        this.$emit('vipLeveList', this.selectAllData)
      this.close()
    },
    //关闭对话框
    close () {
      this.$emit('update:OpenVipLeve', false)
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.selectAllData = selection
    },
    /** 查询分页列表 */
    getList () {
      this.loading = true
      listlevel(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParams.pageNum = 1
      this.getList()
    },
    //清空搜索框
    reset () {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.vipLevetableCom {
  display: flex;
  flex-direction: column;
  //顶部操作
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: row-reverse;
    //输入框
    .searchInput {
      width: 200px;
      margin-right: 5px;
    }
    ::v-deep .el-input--small .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
}
</style>
